import React from 'react'
import {
  CardHeader,
  Button,
  Avatar,
  CardActions,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box
} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { Link } from 'react-router-dom'
import { userStore } from '../../context/UserContext'
// styles
import useStyles from './styles'

export function PackageCard({ children, ...props }) {
  var {
    title,
    description,
    image,
    icon,
    destination,
    sub_title,
    campaign_type,
    price,
  } = props
  var classes = useStyles()
  const { setPackagePrice } = userStore()
  const user = userStore.getState().user

  function getDestination(campaign_type) {
    if (campaign_type === 'model') {
        return 'validação-de-modelos';
    }
    else if (campaign_type === 'model-nlp') {
        return 'validação-de-modelos-nlp';
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar className={classes.packageHeaderAvatar}>
            {icon !== '' ? <img alt="icon" src={icon} height={25} width={25} /> : null}
          </Avatar>
        }
        title={<span className={classes.packageHeaderText}>{title}</span>}
        subheader={
          <span className={classes.packageSubHeaderText}>{sub_title}</span>
        }
        className={classes.packageHeader}
      />
      <CardMedia className={classes.media} image={image} title="" />
      <CardContent className={classes.packageContent}>
        <Typography className={classes.packageContentText}>
          {description}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
      >
        {destination !== '' ? (
          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-around"
          >
            <Button
              fullWidth
              onClick={() => {
                setPackagePrice(price)
              }}
              component={Link}
              to={{
                pathname: getDestination(campaign_type) ? getDestination(campaign_type) : destination,
                state: {
                  project: props,
                }
              }}
              style={{
                boxShadow: '0px 4px 4px 0px #00000040',
                backgroundColor: '#1A73E8',
                border: '1px solid #6877FF',
                width: '140px',
                color: '#fff',
                fontWeight: '500',
                height: '30px',
                fontSize: '12px',
                borderRadius: '6px',
                padding: '10px',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              INICIAR <PlayArrowIcon fontSize='small' style={{ marginLeft: '7px' }}/>
            </Button>
            {user.is_payed && ( 
              <span className={classes.packageActionPriceLabel}>
                POR R$ {price}
              </span> 
            )}

          </Box>
        ) : (
          <Button
            fullWidth
            className={classes.packageActionButton}
            disabled
          >
            INICIAR AGORA
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default PackageCard
