import { AuthApi } from '../../api'
import { STATUS } from './contants.jsx'

export const handleDisableTool = ({ setTool, setActiveTool, array }) => {
  const allInative = array.map(item => ({
    ...item,
    active: false
  }))
  setTool(allInative)
  setActiveTool('')
}

export const getNextExam = async ({ id, modality }) => {
  try {
    const response = await AuthApi.get(`/api/v1/${modality}/next/${id}`)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const handleChangeData = async ({
  data,
  setValidationContent,
  setPredictions,
  setLoading
}) => {
  try {
    setValidationContent(data)
    setPredictions(
      data.prediction.map(item => ({
        prediction_id: item.id,
        is_present: false,
        prediction_class: item.alert,
        model_version: item?.version || 'v1',
        is_local_correct: false,
        score: item.score
      }))
    )
    setLoading(false)
  } catch (err) {
    console.log('err', err)
  }
}

export const handleSubmit = async ({
  roi_list,
  predictions,
  modality,
  correctLocalComments
}) => {
  try {
    const roi = [{
      "doc_details_id": roi_list.data.id,
      "classification": "VALIDATED",
      "pathology_info_id": roi_list.data.pathology_info_id,
      "body_part": roi_list.data.body_part
      }
    ]

    const response = await AuthApi.post(`/api/v1/${modality}/model-feedback`, {
      roi_list:
        roi.length > 0
          ? roi
          : [
              roi_list.map(item => ({
                ...item,
                pathology: STATUS.validated
              }))[0]
            ],
      predictions: predictions.map(item => {
        const comments =
          correctLocalComments.find(i => i.id === item.prediction_id)
            ?.comment || null
        return {
          ...item,
          validation_text: comments
        }
      })
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const handleNextExam = async ({
  setLoading,
  predictions,
  project,
  studyDetails,
  correctLocalComments,
}) => {
  try {
    setLoading(true)
    await handleSubmit({
      roi_list: studyDetails,
      predictions,
      modality: project.modality,
      correctLocalComments
    })
    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

export const handleFinishCampaing = async ({ id }) => {
  try {
    const response = await AuthApi.put(
      `api/v1/pathologies/status?campaign_id=${id}&status=paused`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const handleSkipcampaing = async ({
  setOpenDialogSkip,
  setLoading,
  predictions,
  project,
  studyDetails,
  setCorrectLocalComments,
}) => {
  try {
    setCorrectLocalComments([])
    setOpenDialogSkip(false)
    setLoading(true)
    await AuthApi.post(`/api/v1/${project.modality}/model-feedback`, {
      roi_list: [
        {
          "doc_details_id": studyDetails.data.id,
          "classification": "SKIP",
          "pathology_info_id": studyDetails.data.pathology_info_id,
          "body_part": studyDetails.data.body_part
          }
      ],
      predictions
    })
    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}
