import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  StepContent,
  Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { VerticalProgress } from '../VerticalProgress/VerticalProgress'
import { NumericalBadge } from '../Wrappers'
import { studyService } from '../../services/studies'
import { userStore } from '../../context/UserContext'
import FinishDialog from './components/FinishDialog'
import Hotkeys from 'react-hot-keys'

import useStyles from './styles'
import { useImperativeHandle } from 'react'
import { forwardRef } from 'react'

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      style={{ backgroundColor: '#ff6b6b' }}
    />
  )
}

export const ViewportSidebarConfirmation = forwardRef(
  (
    {
      className,
      campaignType,
      cornerstoneElement,
      results,
      studyId,
      type,
      nextStudyDetails,
      isSubmit,
      setIsSubmit,
      hidden,
      setAlertTextNer,
      setShowAlertNer
    },
    ref
  ) => {
    const classes = useStyles()
    let { id } = useParams()
    const { setUserBilling, packagePrice } = userStore()
    const [openFinishDialog, setOpenFinishDialog] = useState(false)
    const typeAdj = type === 'ner' ? 'nlp' : type

    const [activeStep, setActiveStep] = useState(() => {
      studyService.countExamDone(typeAdj, id).then(response => {
        return setActiveStep(response % 10)
      })
    })

    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState('')
    const user = userStore.getState().user

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setShowAlert(false)
    }

    const getHandles = element => {
      switch (element['data'].toolName) {
        case 'FreehandRoi':
          return element['data']['measurementData']['handles']['points']
        case 'RectangleRoi':
          return [
            element['data']['measurementData']['handles']['start'],
            element['data']['measurementData']['handles']['end']
          ]
        default:
          return []
      }
    }

    const getImageProps = props => {
      return[{
        "window_center": props.viewport.voi.windowCenter,          
        "window_width": props.viewport.voi.windowWidth,             
        "angle": props.viewport.rotation,      
      }]
    }

    const parseResults = (results, action) => {
      if (action === 'SUBMIT') {
        return results.map(element => {
          return {
            exam_id: element['exam_id'],
            pathology: element['class'],
            imageIndex: element['imageIdIndex'],
            roi_points: getHandles(element),
            study_instance_uid: element['study_instance_uid'],
            serie_instance_uid: element['serie_instance_uid'],
            sop_instance_uid: element['sop_instance_uid'],
            modality: type,
            study_category: id,
            image_props: campaignType == 'quality' ? getImageProps(cornerstoneElement) : []
          }
        })
      } else {
        return [
          {
            exam_id: studyId,
            pathology: 'SKIP',
            imageIndex: 0,
            roi_points: [],
            study_instance_uid: '',
            serie_instance_uid: '',
            sop_instance_uid: '',
            modality: type,
            study_category: id,
            image_props: []
          }
        ]
      }
    }

    const parseResultsNlp = (results, action) => {
      if (action === 'SUBMIT') {
        return results.map(element => {
          return {
            doc_details_id: element['doc_details_id'],
            classification: element['classification'],
            pathology_info_id: element['pathology_info_id'],
            body_part: element['body_part'],
            ner_result: type === 'ner' ? element['ner_result'] : undefined
          }
        })
      } else if (action === 'NOINFO'){
        return [{
          doc_details_id: studyId,
          classification: 'NOINFO',
          body_part: 'GENERAL',
          pathology_info_id: id,
          ner_result: {"start": 1, "end": 2, "tokens": ["NONE"], "parsed_text": null}
        }]
    } else {
        return [
          {
            doc_details_id: studyId,
            classification: 'SKIP',
            body_part: '',
            pathology_info_id: id,
            ner_result: undefined
          }
        ]
      }
    }

    const sendClassification = action => {
      if (isSubmit && action === 'SUBMIT') {
        setAlertText(
          'Please wait for next study to load before submiting again.'
        )
        setAlertTextNer &&
          setAlertTextNer(
            'Please wait for next study to load before submiting again.'
          )
        setShowAlertNer && setShowAlertNer(true)
        return setShowAlert(true)
      }

      if (!results.length && action === 'SUBMIT') {
        setAlertText('Please send specific tags')
        setAlertTextNer && setAlertTextNer('Please send specific tags')
        setShowAlertNer && setShowAlertNer(true)
        return setShowAlert(true)
      }

      if ((nextStudyDetails.length == 0 || isSubmit) && action === 'SKIP') {
        setAlertText('Please wait a few seconds before skipping')
        setAlertTextNer &&
          setAlertTextNer('Please wait a few seconds before skipping')
        setShowAlertNer && setShowAlertNer(true)
        return setShowAlert(true)
      }
      let parsedResults =
        type === 'nlp' || type === 'ner'
          ? parseResultsNlp(results, action)
          : parseResults(results, action)
      setIsSubmit(true)
      studyService.postResults(parsedResults, typeAdj).then(response => {
        if (response.status === 200) {
          setUserBilling()
          studyService.countExamDone(typeAdj, id).then(response => {
            if (response % 10 === 0 && activeStep !== 0) {
              setOpenFinishDialog(true)
            }
            return setActiveStep(response % 10)
          })
          setIsSubmit(false)
        }
      })
    }

    const getExamLabel = type => {
      switch (type) {
        case 'cr':
          return 'X-Ray Exam'
        case 'ct':
          return 'CT Exam'
        case 'nlp':
          return 'NLP Exam'
        case 'ner':
          return 'NLP Exam'
        default:
          return 'Default Exam'
      }
    }

    const getListemItems = Array(10)
      .fill()
      .map((_, i) => (
        <Step key={i}>
          {i < activeStep ? (
            <StepLabel
              classes={{
                iconContainer: classes.tasksListItemSuccessIcon,
                root: classes.tasksStepLabel
              }}
            >
              <Typography
                variant="subtitle2"
                display="block"
                className={classes.tasksListItemHeader}
              >
                {getExamLabel(type)}
              </Typography>
            </StepLabel>
          ) : (
            <StepLabel classes={{ root: classes.tasksStepLabel }}>
              <Typography
                variant="subtitle2"
                className={classes.tasksListItemHeader}
                style={{
                  color:
                    i === activeStep ? '#25445C' : 'rgba(113, 135, 150, 0.38)'
                }}
              >
                {i === activeStep &&
                  (nextStudyDetails.length == 0 || isSubmit) && (
                    <CircularProgress
                      color="secondary"
                      size="10"
                      className={classes.actionLoader}
                    />
                  )}
                {getExamLabel(type)}
              </Typography>
            </StepLabel>
          )}
          {i === activeStep && (
            <StepContent className={classes.tasksActiveStepContent}>
              <Grid container justify="flex-end">
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendClassification('SKIP')
                    }}
                    disabled={nextStudyDetails.length == 0 || isSubmit}
                    className={`${classes.skipButton} ${classes.groupButton}`}
                    size="small"
                  >
                    Pular
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendClassification('SUBMIT')
                    }}
                    disabled={nextStudyDetails.length == 0 || isSubmit}
                    className={`${classes.submitButton} ${classes.groupButton}`}
                    size="small"
                  >
                    ENVIAR
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          )}
        </Step>
      ))

    useImperativeHandle(ref, () => ({
      skip: () => {
        sendClassification('SKIP')
      },
      noInfo: () => {
        sendClassification('NOINFO')
      },
      submit: () => {
        sendClassification('SUBMIT')
      },
      activeStep
    }))

    return (
      <Hotkeys
        keyName="shift+e,shift+space"
        onKeyDown={event => {
          event === 'shift+e'
            ? sendClassification('SUBMIT')
            : sendClassification('SKIP')
        }}
      >
        <Paper
          style={{
            ...(hidden && { display: 'none' })
          }}
          square
          className={classes.sidebarContainer}
          elevation={0}
        >
          <FinishDialog
            openFinishDialog={openFinishDialog}
            setOpenFinishDialog={setOpenFinishDialog}
            price={packagePrice}
          />
          <Snackbar
            open={showAlert}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert severity="error">{alertText}</Alert>
          </Snackbar>
          <Grid container>
            <Grid item xs={10} style={{ height: '70vh' }}>
              <Stepper
                activeStep={activeStep}
                className={classes.stepperContainer}
                orientation="vertical"
              >
                {getListemItems}
              </Stepper>
            </Grid>
            <Grid item xs={2} style={{ minHeight: '75vh', paddingTop: '2vh' }}>
              <VerticalProgress
                progress={(activeStep + 1) * 7}
                style={{ minHeight: 'inherit' }}
              />
            </Grid>
            <Grid item xs={12} className={classes.bonusContainer}>
              <Grid container direction="column" alignItems="stretch">
                <Grid item xs={12} style={{ marginRight: '2vh' }}>
                {user.is_payed && ( 
                  <NumericalBadge
                    headerText={''}
                    totalValue={'R$ ' + packagePrice}
                  />
                )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Hotkeys>
    )
  }
)

export default ViewportSidebarConfirmation
