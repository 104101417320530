import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, IconButton, Menu, Box } from '@material-ui/core'
import { AccountCircle as AccountIcon } from '@material-ui/icons'

// styles
import useStyles from './styles'

// components
import { Typography, Button, NumericalBadge } from '../Wrappers'

// context
import { signOut, userStore } from '../../context/UserContext'

export default function Header(props) {
  var classes = useStyles()
  const { totalEarning, nextPayment } = props
  // local
  var [profileMenu, setProfileMenu] = useState(null)
  const user = userStore.getState().user
  const removeUserData = userStore.getState().removeUserData
  const setAuthentication = userStore(state => state.setAuthentication)

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar
        variant="dense"
        className={classes.toolbar}
        disableGutters={true}
      >
        <Button component={Link} to={'/app/dashboard'} size={'small'}>
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            FLORENCE
            <Typography
              variant="caption"
              display="inline"
              className={classes.logoBeta}
            >
              Beta
            </Typography>
          </Typography>
        </Button>

        <div className={classes.grow} />
        {
          nextPayment && user.is_payed && (
            <NumericalBadge  classHeaderText="text-[#fff]" headerText={'Próximo Pagamento'} totalValue={'R$' + nextPayment}/>
          )
        }
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {user.full_name}
            </Typography>
          </div>
          {user.is_superuser && (
            <div className={classes.profileMenuUser}>
              <Button component={Link} to={'/app/campaign'} size={'small'}>
                Criar
              </Button>
              <Button component={Link} to={'/app/validate'} size={'small'}>
                Validar
              </Button>
            </div>
          )}
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => {
                setAuthentication()
                removeUserData()
                signOut(props.history)
              }}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
