import React, { useState, useEffect } from 'react'
import { TokenAnnotator } from 'react-text-annotate'
import { Box, Card, CardContent } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import { ArrowDropDownCircle } from '@material-ui/icons'
export default function Annotator(props) {
  const [state, setState] = props.state
  const [tokens, setTokens] = useState(props.studyDetails.data.text)
  const [previousTokens, setPreviousTokens] = useState()
  const [tag] = props.tag
  const [contextTags] = props.contextTagsState
  const handleChange = value => {
    setState(value)
  }
  
  if (props.studyDetails.data.text !== previousTokens) {
    setTokens(props.studyDetails.data.text)
    setPreviousTokens(props.studyDetails.data.text)
    setState([])
  }

  useEffect(() => {
    if (state.length > 0) {
      props.addClassification(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const delete_ = start => {
    const n_state = state.filter(val => val.start !== start)
    setState(n_state)
  }

  const AnnotatedEntity = props => {
    const { content, color, start, tag } = props
    return (
      <span className={`${color} mx-2 px-2 py-1 rounded-sm`}>
        {content}
        {''}
        <span className=" font-bold text-xs p-1">
          {' '}
          {tag}{' '}
          <CloseIcon
            className="cursor-pointer"
            onClick={() => delete_(start)}
          />
        </span>
      </span>
    )
  }
  const [showAllText, setShowAllText] = useState(false)
  return (
    <div className=" flex flex-col">
      <Box p={1}>
        <Card
          square={true}
          elevation={3}
          variant="elevation"
          className="relative"
          style={{
            overflow: 'inherit'
          }}
        >
          <ArrowDropDownCircle
            style={{
              transform: showAllText ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
            className="cursor-pointer absolute left-1/2 text-[#1669E5] text-[22px] transform -translate-x-1/2 bottom-[-14px]"
            onClick={() => setShowAllText(prevState => !prevState)}
          />
          <CardContent
            className="overflow-auto"
            style={{
              maxHeight: showAllText ? '1000px' : '400px'
            }}
          >
            <TokenAnnotator
              style={{whiteSpace: "pre-line"}}
              className="leading-10"
              tokens={tokens.replace(/\n+/g, '\n ').split(' ')}
              value={state}
              onChange={handleChange}
              renderMark={AnnotatedEntity}
              getSpan={span => ({
                ...span,
                tag: contextTags.filter(item => item.active)[0].labels,
                color: 'bg-' + tag.color
              })}
            />
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}
