import React from "react";
import { Avatar, ListItem, 
   ListItemAvatar,ListItemText, Box
} from "@material-ui/core";
import { userStore } from "../../../../context/UserContext";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classnames from "classnames";

// styles
import useStyles from "./styles";

export default function StatusHeader(props) {
  var classes = useStyles();
  const user = userStore(state => state.user);
  const userBilling = userStore(state => state.userBilling);

  return (
    <div>
      <Box display="flex">
        <Box p={1} flexGrow={1}>
          <ListItem className={classes.statusItem}>
            <ListItemText primary={'Bem Vindo(a), '+user.full_name} className={classes.statusLabel}/>
          </ListItem>
          <ListItem className={classes.statusItem}>
            <ListItemAvatar className={classes.statusSecondary}>
              <Avatar className={classes.statusAvatar}>
                <CheckCircleIcon fontSize="small"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Status: Verified" className={classes.statusLabel}/>
          </ListItem>
        </Box>
        <Box p={1}>
          <ListItem className={classes.statusItem}>
          {user.is_payed && ( 
            <ListItemText primary="Próximo Pagamento" className={classes.statusLabel}/>
          )}
            
          </ListItem>
          {user.is_payed && (
          <ListItem className={classes.statusItem}>
            <ListItemText 
              primary={ 
                <span>
                R$ <span class='next-payment-value'>{userBilling.nextPayment}</span>
                </span>
              }
              className={classnames(classes.statusLabel, classes.nextPaymentLabel)}/>
          </ListItem>
          )}
        </Box>
      </Box>
    </div>
  );
}
