import React from "react";
import { useHistory } from 'react-router-dom'
import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core';
import {NumericalBadge} from '../../../Wrappers';
import { userStore } from "../../../../context/UserContext";


// styles
import useStyles from "./styles";

export default function FinishDialog(props) {

  const {openFinishDialog, setOpenFinishDialog, price} = props;

  var classes = useStyles();
  let history = useHistory();
  const userBilling = userStore.getState().userBilling
  const user = userStore.getState().user
  
  const handleClose = (event, reason) => {
    setOpenFinishDialog(false)
  }

  return (
    <Dialog open={openFinishDialog} maxWidth={'xs'} fullWidth={true}
      onClose={handleClose} className={classes.finishDialogContainer} 
      PaperProps={{style: { borderRadius: 0, textAlign: 'center', minHeight: '50%' }}}>
      <DialogTitle className={classes.finishDialogTitleContainer}>
        <span className={classes.finishDialogTitle}>Bom trabalho, Doutor</span>
        {user.is_payed && ( 
          <span className={classes.finishDialogSubTitle}>Você ganhou R$ {price}</span>     
        )}
 
      </DialogTitle>
      {user.is_payed && ( 
      <DialogContent>
        <DialogContentText>
          <span className={classes.finishDialogContent}>Seu novo total é</span>
            <div className={classes.finishDialogChip}><NumericalBadge headerText={''} totalValue={'R$' + userBilling.totalEarning}/></div> 
        </DialogContentText>
        <img src="/static/images/chest.gif" width="200" height="200"/>
      </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={() => {return history.push('/app/dashboard')}} 
          className={`${classes.skipButton} ${classes.groupButton}`}>
            Voltar a seleção
        </Button>
        <Button variant="contained" onClick={() => {setOpenFinishDialog(false)}} 
          className={`${classes.submitButton} ${classes.groupButton}`}>
            Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
