import React from "react";
import {
  AppBar,
  Toolbar,
  LinearProgress,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";

// styles
import useStyles from "./styles";


// image
import {NumericalBadge} from "../../../../components/Wrappers";

// context
import { userStore } from "../../../../context/UserContext";

export default function ProgressHeader(props) {
  const {monthlyProgress, monthlyGoal} = props;
  const user = userStore.getState().user
  var classes = useStyles();

  const normalize = (progress, goal) => Math.min(100,(monthlyProgress/monthlyGoal)*100);

  // global
  const userBilling = userStore(state => state.userBilling);

  const progressionTheme = createMuiTheme({
    palette: {
       primary: {
        main: "#255A23"
       }
    }
  })

  return (
    <AppBar position="static" className={classes.appBar}  >
      <Toolbar className={classes.progressToolBar} >
      {user.is_payed && (
        <NumericalBadge headerText={'Total Conquistado'} totalValue={'R$' + userBilling.totalEarning}/>
      )}
        <NumericalBadge headerText={'Packs Completos'} totalValue={userBilling.setsCompleted} isNotMoney/>
        <div  className={classes.progressItem}>
          <p className={classes.progressItemTitle}>Meta Mensal</p>
          
          <ThemeProvider theme={progressionTheme}>
            <LinearProgress className={classes.progressBar} variant="determinate" color="primary" value={normalize(monthlyProgress,monthlyGoal)} />
          </ThemeProvider>
        </div>
          

      </Toolbar >
      
    </AppBar>
  );
}
